import sniffer from 'sniffer'
import FastClick from 'fastclick'
import * as utils from './utils.js';
import {initHome} from './pages/home.js';

class App {

  constructor(opt = {}) {

	window.scrollTo(0,0)
	//console.log("%cCreated by Alfred","color: #000; padding: 5px 0px;");

	this.init()
	this.addEvents()

  }

  init() {

	FastClick(document.body);

	//removeLoading
	document.querySelector('body').classList.remove('is-loading');

	//routes
	initHome();

  $(function () {
	  if ( $(".formNewsletter").length > 0){
		  $(".formNewsletter").submit(function (e) {
			  e.preventDefault();

			  var form = $(this);
			  var callback = form.find(".callback");

			  var ajaxurl = form.data("ajaxurl");
			  var data = form.serializeArray();

			  $.ajax({
				  type: "POST",
				  url: ajaxurl,
				  data: data,
				  success : function (data) {
					  callback.removeClass("error")
						  .addClass("success")
						  .text(data)
				  },
				  error : function (response) {
					  callback.removeClass("success")
						  .addClass("error")
						  .text(response.responseText)
				  }
			  });

		  });
	  }
  })



	//smoothScroll
	$('a[href^="#"]').click(function(){
	  var the_id = $(this).attr("href");

	  $('html, body').animate({
		scrollTop:$(the_id).offset().top-200
	  }, 'slow');
	  return false;
	});

      $(".readmore_achievement").click(function (e) {
          e.preventDefault();
          $(this).siblings(".achievement_content").toggle()

          var span  = $(this).find("span");
          span.text(span.text() === "Show more" ? "Show less" : "Show more");
      })




  }

  addEvents() {

	window.onbeforeunload = function() {window.scrollTo(0,0);}

  }

}

const app = new App();
