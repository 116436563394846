export function initHome() {


		var mySwiper = new Swiper ('.swiper-container', {
			// Optional parameters
			slidesPerView: 3,
			loop: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1200: {
					slidesPerView: 2,
					spaceBetween: 10
				},
				768: {
					slidesPerView: 1,
					spaceBetween: 10
				},
			}
		})

}
